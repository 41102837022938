const Footer=()=>{
    return (
        <section className="bg-[#00A5A5] footer" id="contact">
            <div className="container mx-auto pt-16 pb-20  px-6 md:px-16">
               <div className="flex  flex-col md:flex-row justify-start  md:justify-between  md:items-center mb-16">
               <h1>Looking for excellent medical care? Call Us Anytime!</h1>
               <h3>±25470012555</h3>
              
               </div>
               <hr />
               <p>© 2023 Kwangu Health. All Rights Reserved.</p>
            </div>
        </section>
    )

}
export default Footer