const SectionThree = () => {
  return (
    <section className="container mx-auto section-3 py-12 h-fit">
      <h1 className="md:my-10">What Makes Kwangu Health the Best</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        <div className="card p-8 space-y-6">
          <i class="fa-solid fa-clock-rotate-left text-[#B2D235]" style={{fontSize:'44px'}}></i>
          <h3>24/7 Availability</h3>
          <p>
            Kwangu Health App is available all round the clock for your Home
            Care needs. With the use of Technology in receiving requests of
            services, our AI enabled solution is able to identify and locate an
            appropriate Service Provider in a timely manner.
          </p>
        </div>
        <div className="card p-8 space-y-6">
        <i class="fa-solid fa-check text-[#B2D235]" style={{fontSize:'44px'}}></i>
          <h3>Verified Service Providers</h3>
          <p>
            We pride in ensuring that our listed Home Care providers are vetted
            and verified before being approved to be on Kwangu Health App. This
            ensures our customers of highly qualified and experienced service
            providers are routed to your requested services.
          </p>
        </div>
        <div className="card p-8 space-y-6">
        <i class="fa-solid fa-laptop-medical text-[#B2D235]" style={{fontSize:'44px'}}></i>

          <h3>Personalized Care Service</h3>
          <p>
            Our Service Providers always ensure our customers are comfortable at
            all times during the active session. Your well being as our customer
            is very important in maintaining our track record to offer unmatched
            services.
          </p>
        </div>
      </div>
    </section>
  );
};
export default SectionThree;
