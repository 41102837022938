const SectionTwo = () => {
  return (
    <section className="container mx-auto py-10 md:py-28 px-5">
      <div className="flex flex-col-reverse md:flex-row justify-around items-start section-2 md:gap-16 flex-wrap">
        <div>
          <img src="images/section-2.png" alt="kwangu-health" loading="lazy"/>
        </div>
        <div className="space-y-10">
          <h1>Welcome To Kwangu Health</h1>
          <p>
            After being discharged from the hospital, it can be challenging to
            keep track of your recovery and manage your healthcare needs. That's
            why we've designed this app to help you stay connected with your
            healthcare providers and manage your healthcare needs from the
            comfort of your home.
          </p>
          <div className="flex justify-start items-center experience gap-20">
            <div>
              <span>2</span>
              <p>Years of Experience</p>
            </div>
            <div>
              <span>20k+</span>
              <p>Patients</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default SectionTwo;
