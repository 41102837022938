import { useState } from "react";

const Navbar = () => {
  const [isOpen, setisOpen] = useState(false);
  const handleClick = () => {
    setisOpen(!isOpen);
  };
  return (
    <section className="navbar sticky top-0 z-40 bg-primary">
      <div className="container mx-auto">
        <div className="flex items-center justify-between">
          <div>
            <a href="/">
              <img src="images/kwangu-logo.png" alt=""  className="logo"/>
              
            </a>
          </div>
          <div className="hidden md:flex items-center gap-10">
            <a href="privacy-policy" className="text-white">
              Privacy Policy
            </a>
            <a href="#contact">
              <button>Contact Us</button>
            </a>
          </div>

          {!isOpen && (
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="block md:hidden"
              onClick={handleClick}
            >
              <path
                d="M3.90625 12.5H21.0938"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.90625 6.25H21.0938"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.90625 18.75H21.0938"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          )}
          {isOpen && (
          
             
              <div className="flex flex-col gap-4 bg-white z-10 w-[150px] items-end p-3 -mr-6">
              <i
                class="fa-solid fa-x text-32 text-black block md:hidden"
                onClick={handleClick}
              ></i>
                <a href="privacy-policy" className="text-black">
                  Privacy Policy
                </a>
                <a href="#contact" className="text-black">Contact Us
                </a>
              </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Navbar;
