const Sidebar = () => {
  return (
    <section className="sidebar">
      <ol className="space-y-6">
        <li>
          <a href="#introductions">Introductions</a>
        </li>
        <li>
          <a href="#definitions">Defintions</a>
        </li>
        <li>
          <a href="#collection">Information Collection and use</a>
        </li>
        <li>
          <a href="#types">Types of data Collected</a>
        </li>
        <li>
          <a href="#use">Use of Data</a>
        </li>
        <li>
          <a href="#retention">Retention of Data</a>
        </li>
        <li>
          <a href="#transfer">Transfer of Data</a>
        </li>
        <li>
          <a href="#disclosure">Security of Data</a>
        </li>
        <li>
          <a href="#security">Disclosure of Data</a>
        </li>
        <li>
          <a href="#service">Service Providers</a>
        </li>
        <li>
          <a href="#analytics">Analytics</a>
        </li>
        <li>
          <a href="#ci">CI/CD Tools</a>
        </li>
        <li>
          <a href="#remarketing">Behaviorial Remarketing </a>
        </li>
        <li>
          <a href="#sites">Links to other sites</a>
        </li>
        <li>
          <a href="#children">Children's Policy</a>
        </li>
        <li>
          <a href="#changes">Changes to this Privacy Policy</a>
        </li>
        <li>
          <a href="#contact">Contact Us</a>
        </li>
      </ol>
    </section>
  );
};

export default Sidebar;
