const SectionFour = () => {
  return (
    <section className=" container-fluid bg-[rgba(178, 210, 53, 0.1)] about">
      <div className="container mx-auto py-8 md:py-[72px]  px-5 md:px-36">
        <h1 className=" mb-8 md:mb-12">About Kwangu Health</h1>
        <p>
          Our app provides you with convenient and accessible healthcare
          services, including virtual consultations with healthcare providers.
          We believe that this app will help you stay on track with your
          recovery and improve your overall health outcomes. <br /> The app
          allows a patient to access medical care after a hospital visit. The
          app allows a user to add dependants who cannot access the app for
          instance the elderly and children. Once these dependants are added
          they can be booked for a service. The app would ensure quick service
          delivery by ensuring doctors providing the service are near the user's
          location. The users pay a convenience fee upfront then after the
          service has been offered they pay the service charge. Payment can be
          made in cash, mobile money(M-Pesa) or card. They then receive a
          medical report from the doctor with summary of the service provided.
        </p>
        <div className="flex flex-col md:flex-row justify-center items-center mt-10 gap-4">
          <a
            href="https://play.google.com/store/search?q=KwanguHealth&c=apps"
            target={"_blank"}
          >
            {" "}
            <img src="images/google-play.png" alt="appstore" loading="lazy" />
          </a>
          <a href="https://play.google.com/store/search?q=KwanguHealth&c=apps">
            <img src="images/app-store.png" alt="playstore" loading="lazy" />
          </a>
        </div>
      </div>
    </section>
  );
};
export default SectionFour;
