import Navbar from "./shared/navbar";

const HeroSection = () => {
  return (
    <section className="container-fluid h-fit bg-primary relative pb-8 md:pb-28 pt-8 px-5 md:px-0">
      <Navbar/>
      <div className="container mx-auto p-0">
        <div className="w-full grid grid-cols-1 md:grid-cols-2 hero items-center justify-between  mt-16 md:mt-32  pl-0 ">
        <div className="relative mb-10 lg:mb-0  flex  md:hidden justify-end ">
         <img src="images/hero-1.png" alt="" className="-mr:32" loading="lazy"/>
            <div className="hero-bg-2 rounded-full"></div>
        </div>
  <div className=" flex flex-col justify-center md:justify-start mb-11 md:mb-0">
        <h1 className="text-title text-light mb-[14px]">Your health, your priority</h1>
        <p className="text-subtitle text-light mb-[45px]">
          Take control of your health with our app. Our goal is to provide you
          with a comprehensive and convenient way to manage your post-hospital
          care, so you can focus on your recovery and getting back to your
          normal activities. We are committed to providing you with the highest
          quality care, and we look forward to supporting you on your journey to
          better health.
        </p>
        <a href="https://play.google.com/store/search?q=KwanguHealth&c=apps" target={'_blank'}><button className="btn-text">Get Started</button></a>
       
        </div>
        <div className="relative mb-10 lg:mb-0 hidden md:flex  justify-end ">
         <img src="images/hero-1.png" alt="" className="-mr:32" loading="lazy"/>
            <div className="hero-bg-2 rounded-full"></div>
        </div>
        </div>
      
        <div>

        </div>
      </div>
    </section>
  );
};
export default HeroSection;
