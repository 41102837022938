import logo from './logo.svg';
import './App.css';
import HeroSection from './components/hero';
import SectionTwo from './components/section-two';
import SectionThree from './components/section-three';
import SectionFour from './components/section-four';
import Footer from './components/shared/footer';
import SectionFive from './components/section-five';

function App() {
  return (
    <div className="App">
     <HeroSection/>
     <SectionTwo/>
     <SectionThree/>
     <SectionFour/>
     <SectionFive/>
     <Footer/>
    </div>
  );
}

export default App;
