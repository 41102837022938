import ContentSection from "../components/privacy-policy/content-section";
import Sidebar from "../components/privacy-policy/sidebar";
import Footer from "../components/shared/footer";
import Navbar from "../components/shared/navbar";

const PrivacyPolicy = () => {
  return (
    <div>
      <div className="bg-primary py-10">
        <Navbar />
      </div>
      <div className="container mx-auto flex flex-col md:flex-row justify-between px-5 py-12 gap-28">
        <Sidebar />
        <ContentSection />
      </div>

      <Footer />
    </div>
  );
};
export default PrivacyPolicy;
