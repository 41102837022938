const SectionFive = () => {
  return (
    <section>
      <div className="container mx-auto section-5  px-5 py-16">
        <div className="flex flex-col md:flex-row justify-around contact-details flex-wrap">
          <img src="images/section-3.png" alt="kwangu-health" loading="lazy" />
          <div className="space-y-6 content-side">
            <h1>Whenever You Need Us, We're Here 24 Hours a Day</h1>
            <div className="flex justify-start items-center bg-[#F9F9F9] rounded-lg h-[104px] pl-[16px] gap-[24px]">
              <div className="flex items-center bg-[#B2D235] h-[72px] w-[72px] rounded-lg justify-center">
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.4166 24.9163L15.3333 27.9997C14.6833 28.6497 13.65 28.6497 12.9833 28.0163C12.8 27.833 12.6166 27.6663 12.4333 27.483C10.7166 25.7497 9.16665 23.933 7.78331 22.033C6.41665 20.133 5.31665 18.233 4.51665 16.3497C3.73331 14.4497 3.33331 12.633 3.33331 10.8997C3.33331 9.76634 3.53331 8.68301 3.93331 7.68301C4.33331 6.66634 4.96665 5.73301 5.84998 4.89967C6.91665 3.84967 8.08331 3.33301 9.31665 3.33301C9.78331 3.33301 10.25 3.43301 10.6666 3.63301C11.1 3.83301 11.4833 4.13301 11.7833 4.56634L15.65 10.0163C15.95 10.433 16.1666 10.8163 16.3166 11.183C16.4666 11.533 16.55 11.883 16.55 12.1997C16.55 12.5997 16.4333 12.9997 16.2 13.383C15.9833 13.7663 15.6666 14.1663 15.2666 14.5663L14 15.883C13.8166 16.0663 13.7333 16.283 13.7333 16.5497C13.7333 16.683 13.75 16.7997 13.7833 16.933C13.8333 17.0663 13.8833 17.1663 13.9166 17.2663C14.2166 17.8163 14.7333 18.533 15.4666 19.3997C16.2166 20.2663 17.0166 21.1497 17.8833 22.033C18.05 22.1997 18.2333 22.3663 18.4 22.533C19.0666 23.183 19.0833 24.2497 18.4166 24.9163Z"
                    fill="white"
                  />
                  <path
                    d="M36.6167 30.5504C36.6167 31.0171 36.5333 31.5004 36.3667 31.9671C36.3167 32.1004 36.2667 32.2337 36.2 32.3671C35.9167 32.9671 35.55 33.5337 35.0667 34.0671C34.25 34.9671 33.35 35.6171 32.3333 36.0337C32.3167 36.0337 32.3 36.0504 32.2833 36.0504C31.3 36.4504 30.2333 36.6671 29.0833 36.6671C27.3833 36.6671 25.5667 36.2671 23.65 35.4504C21.7333 34.6337 19.8167 33.5337 17.9167 32.1504C17.2667 31.6671 16.6167 31.1837 16 30.6671L21.45 25.2171C21.9167 25.5671 22.3333 25.8337 22.6833 26.0171C22.7667 26.0504 22.8667 26.1004 22.9833 26.1504C23.1167 26.2004 23.25 26.2171 23.4 26.2171C23.6833 26.2171 23.9 26.1171 24.0833 25.9337L25.35 24.6837C25.7667 24.2671 26.1667 23.9504 26.55 23.7504C26.9333 23.5171 27.3167 23.4004 27.7333 23.4004C28.05 23.4004 28.3833 23.4671 28.75 23.6171C29.1167 23.7671 29.5 23.9837 29.9167 24.2671L35.4333 28.1837C35.8667 28.4837 36.1667 28.8337 36.35 29.2504C36.5167 29.6671 36.6167 30.0837 36.6167 30.5504Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className="contact">
                <h3>Contact Us</h3>
                <p>±25470012555</p>
              </div>
            </div>
            <div className="flex justify-start items-center bg-[#F9F9F9] rounded-lg h-[104px] pl-[16px] gap-[24px]">
              <div className="flex items-center bg-[#B2D235] h-[72px] w-[72px] rounded-lg justify-center">
                <svg
                  width="37"
                  height="37"
                  viewBox="0 0 37 37"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M30.8333 12.3337L18.5 20.042L6.16665 12.3337V9.25033L18.5 16.9587L30.8333 9.25033M30.8333 6.16699H6.16665C4.4554 6.16699 3.08331 7.53908 3.08331 9.25033V27.7503C3.08331 28.5681 3.40816 29.3523 3.9864 29.9306C4.56464 30.5088 5.3489 30.8337 6.16665 30.8337H30.8333C31.6511 30.8337 32.4353 30.5088 33.0136 29.9306C33.5918 29.3523 33.9166 28.5681 33.9166 27.7503V9.25033C33.9166 8.43257 33.5918 7.64832 33.0136 7.07008C32.4353 6.49184 31.6511 6.16699 30.8333 6.16699Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className="contact">
                <h3>Customer Service</h3>
                <p>care@kwangu.health</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionFive;
