const ContentSection = () => {
  return (
    <section className="content space-y-6">
      <ol>
        <li> <div className="intro" id="introductions">
        <h1>Introduction</h1>
        <h3>Welcome to Kwangu Health</h3>
        <p>
          <span>EdgeTech Consults Ltd</span> (“us”, “we”, or “our”) operates
          www.kwangu.health(hereinafter referred to as “Service”). We use
          your data to provide and improve Service. By using Service, you agree
          to the collection and use of information in accordance with this
          policy. Unless otherwise defined in this Privacy Policy, the terms
          used in this Privacy Policy have the same meanings as in our Terms and
          Conditions. Our Terms and Conditions (“Terms”) govern all use of our
          Service and together with the Privacy Policy constitutes your
          agreement with us (“agreement”).
        </p>
      </div></li>
      <li><div id="definitions">
        <h1>Definitions</h1>
        <h3>
          {" "}
          <span>SERVICE</span> means the www.kwangu.health website operated
          by EdgeTech Consults Ltd
        </h3>
        <p>
          ,<span>PERSONAL DATA</span> means data about a living individual who
          can be identified from those data (or from those and other information
          either in our possession or likely to come into our possession).{" "}
          <br />
          <span>USAGE DATA</span> is data collected automatically either
          generated by the use of Service or from Service infrastructure itself
          (for example, the duration of a page visit). COOKIES are small files
          stored on your device (computer or mobile device). <br />
          <span>DATA CONTROLLER</span> means a natural or legal person who
          (either alone or jointly or in common with other persons) determines
          the purposes for which and the manner in which any personal data are,
          or are to be, processed. For the purpose of this Privacy Policy, we
          are a Data Controller of your data. <br />
          <span>DATA PROCESSORS </span>(OR SERVICE PROVIDERS) means any natural
          or legal person who processes the data on behalf of the Data
          Controller. We may use the services of various Service Providers in
          order to process your data more effectively. <br />
          <span>DATA SUBJECT</span> is any living individual who is the subject
          of Personal Data. <br />
          <span>THE USER</span> is the individual using our Service. The User
          corresponds to the Data Subject, who is the subject of Personal Data.
        </p>
      </div></li>
      <li>
                  
      <div id="collection">
        <h1>Information Collection and Use</h1>
        <p>
          We collect several different types of information for various purposes
          to provide and improve our Service to you.
        </p>
      </div>
      </li>
      <li>
        
      <div id="types">
        <h1>Types of Data Collected</h1>
        <ul>
          <li>
            <span> Personal Data</span>
            <p>
              {" "}
              While using our Service, we may ask you to provide us with certain
              personally identifiable information that can be used to contact or
              identify you (“Personal Data”). Personally, identifiable
              information may include, but is not limited to: <br />
              <br />
              0.1. Email address <br /> 0.2. First name and last name <br />{" "}
              0.3. Phone number <br /> 0.4. Address, Country, State, Province,
              ZIP/Postal code, City <br /> 0.5. Cookies and Usage Data <br />{" "}
              <br /> We may use your Personal Data to contact you with
              newsletters, marketing or promotional materials and other
              information that may be of interest to you. You may opt out of
              receiving any, or all, of these communications from us by
              following the unsubscribe link.
            </p>
          </li>
          <li>
            <span>Usage Data</span>
            <p>
              We may also collect information that your browser sends whenever
              you visit our Service or when you access Service by or through any
              device (“Usage Data”). <br /> <br />
              This Usage Data may include information such as your computer’s
              Internet Protocol address (e.g. IP address), browser type, browser
              version, the pages of our Service that you visit, the time and
              date of your visit, the time spent on those pages, unique device
              identifiers and other diagnostic data. <br /> <br />
              When you access Service with a device, this Usage Data may include
              information such as the type of device you use, your device unique
              ID, the IP address of your device, your device operating system,
              the type of Internet browser you use, unique device identifiers
              and other diagnostic data.
            </p>
          </li>
          <li>
            <span>Location Data</span>
            <p>
              We may use and store information about your location if you give
              us permission to do so (“Location Data”). We use this data to
              provide features of our Service, to improve and customize our
              Service. <br /> <br />
              You can enable or disable location services when you use our
              Service at any time by way of your device settings.
            </p>
          </li>
          <li>
            <span>Tracking Cookies Data</span>
            <p>
              We use cookies and similar tracking technologies to track the
              activity on our Service and we hold certain information. <br />{" "}
              <br /> Cookies are files with a small amount of data which may
              include an anonymous unique identifier. Cookies are sent to your
              browser from a website and stored on your device. Other tracking
              technologies are also used such as beacons, tags and scripts to
              collect and track information and to improve and analyze our
              Service. <br /> <br /> You can instruct your browser to refuse all
              cookies or to indicate when a cookie is being sent. However, if
              you do not accept cookies, you may not be able to use some
              portions of our Service. <br /> <br /> Examples of Cookies we use:{" "}
              <br /> 0.1. Session Cookies: We use Session Cookies to operate our
              Service. <br /> 0.2. Preference Cookies: We use Preference Cookies
              to remember your preferences and various settings. <br /> 0.3.
              Security Cookies: We use Security Cookies for security purposes.{" "}
              <br />
              0.4. Advertising Cookies: Advertising Cookies are used to serve
              you with advertisements that may be relevant to you and your
              interests.
            </p>
          </li>
          <li>
            <span>Other Data</span>
            <p>
              While using our Service, we may also collect the following
              information: sex, age, date of birth, place of birth, passport
              details, citizenship, registration at place of residence and
              actual address, telephone number (work, mobile), details of
              documents on education, qualification, professional training,
              employment agreements, non-disclosure agreements, information on
              bonuses and compensation, information on marital status, family
              members, social security (or other taxpayer identification)
              number, office location and other data.
            </p>
          </li>
        </ul>
      </div>
      </li>
      <li>
      <div id="use">
        <h1>Use of Data</h1>
        <h3>
          {" "}
          <span>EdgeTech Consults Ltd</span> uses the collected data for various
          purposes:
        </h3>
        <p>
          0.1. to provide and maintain our Service;
          <br />
          0.2. to notify you about changes to our Services; <br />
          0.3. to allow you to participate in interactive features of our
          Services when you choose to do so; <br />
          0.4. to provide customer support; <br />
          0.5. to gather analysis or valuable information so that we can improve
          our Services; <br />
          0.6. to monitor the usage of our Services; <br />
          0.7. to detect, prevent and address technical issues; <br />
          0.8. to fulfil any other purpose for which you provide it; <br />
          0.9. to carry out our obligations and enforce our rights arising from
          any contracts entered into between you and us, including for billing
          and collection; <br />
          10. to provide you with notices about your account and/or
          subscription, including expiration and renewal notices,
          email-instructions, etc.;
          <br />
          11. to provide you with news, special offers and general information
          about other goods, services and events which we offer that are similar
          to those that you have already purchased or enquired about unless you
          have opted not to receive such information; <br />
          12. in any other way we may describe when you provide the information;{" "}
          <br />
          13. for any other purpose with your consent.
        </p>
      </div>
      </li>
      <li> <div id="retention">
        <h1>Retention of Data</h1>
        <p>
          We will retain your Personal Data only for as long as is necessary for
          the purposes set out in this Privacy Policy. We will retain and use
          your Personal Data to the extent necessary to comply with our legal
          obligations (for example, if we are required to retain your data to
          comply with applicable laws), resolve disputes, and enforce our legal
          agreements and policies. <br />
          <br />
          We will also retain Usage Data for internal analysis purposes. Usage
          Data is generally retained for a shorter period, except when this data
          is used to strengthen the security or to improve the functionality of
          our Service, or we are legally obligated to retain this data for
          longer time periods
        </p>
      </div></li>
      <li><div id="tranfer">
        <h1>Transfer of Data</h1>
        <p>
          Your information, including Personal Data, may be transferred to – and
          maintained on – computers located outside of your state, province,
          country, county or other governmental jurisdiction where the data
          protection laws may differ from those of your jurisdiction. <br />
          If you are located outside Kenya and choose to provide information to
          us, please note that we transfer the data, including Personal Data, to
          Kenya and process it there. <br />
          Your consent to this Privacy Policy followed by your submission of
          such information represents your agreement to that transfer. <br />
          EdgeTech Consults Ltd will take all the steps reasonably necessary to ensure
          that your data is treated securely and in accordance with this Privacy
          Policy and no transfer of your Personal Data will take place to an
          organisation or a country unless there are adequate controls in place
          including the security of your data and other personal information.
        </p>
      </div></li>
      <li>
        
      
      <div id="disclosure">
        <h1>Disclosure of Data</h1>
        <p>
          We may disclose personal information that we collect, or you provide:{" "}
          <br />
          0.1. Business Transaction.If we or our subsidiaries are involved in a
          merger, acquisition or asset sale, your Personal Data may be
          transferred. <br />
          0.2. Other cases. We may disclose your information also: <br />
          0.2.1. to our subsidiaries and affiliates; <br />
          0.2.2. to contractors, service providers, and other third parties we
          use to support our business; <br />
          0.2.3. to fulfill the purpose for which you provide it; <br />
          0.2.4. for the purpose of including your company’s logo on our
          website; <br />
          0.2.5. for any other purpose disclosed by us when you provide the
          information; <br />
          0.2.6. with your consent in any other cases; <br />e believe
          disclosure is necessary or appropriate to protect the rights,
          property, or safety of the Company, our customers, or others.
        </p>
      </div>
      </li>
      <li>      <div id="security">
        <h1>Security of Data</h1>
        <p>
          The security of your data is important to us but remember that no
          method of transmission over the Internet or method of electronic
          storage is 100% secure. While we strive to use commercially acceptable
          means to protect your Personal Data, we cannot guarantee its absolute
          security.
        </p>
      </div></li>
      <li>      <div id="#service">
        <h1>Service Providers</h1>
        <p>
          We may employ third party companies and individuals to facilitate our
          Service (\ “Service Providers”), provide Service on our behalf,
          perform Service-related services or assist us in analysing how our
          Service is used. <br />
          These third parties have access to your Personal Data only to perform
          these tasks on our behalf and are obligated not to disclose or use it
          for any other purpose.
        </p>
      </div></li>
      <li>      <div id="analytics">
        <h1>Analytics</h1>
        <p>
          We may use third-party Service Providers to monitor and analyze the
          use of our Services.
        </p>
      </div></li>
      <li>      <div id="ci">
        <h1>CI/CD Tools</h1>
        <p>
          We may use third-party Service Providers to automate the development
          process of our Services.
        </p>
      </div></li>
      <li>      <div id="remarketing">
        <h1>Behavioral Remarketing</h1>
        <p>
          We may use remarketing services to advertise on third party websites
          to you after you visited our Service. We and our third-party vendors
          use cookies to inform, optimise and serve ads based on your past
          visits to our Service.
        </p>
      </div></li>
            <li className="#sites">
              
      <div>
        <h1>Links to Other Sites</h1>
        <p>
          Our Service may contain links to other sites that are not operated by
          us. If you click a third party link, you will be directed to that
          third party’s site. We strongly advise you to review the Privacy
          Policy of every site you visit. <br />
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third party sites or services.
        </p>
      </div>
            </li>
            <li>
            <div  id="#children">
        <h1>Children's Privacy</h1>
        <p>
          Our Services are not intended for use by children under the age of 18
          (“Child” or “Children”). <br /> <br />
          We do not knowingly collect personally identifiable information from
          Children under 18. If you become aware that a Child has provided us
          with Personal Data, please contact us. If we become aware that we have
          collected Personal Data from Children without verification of parental
          consent, we take steps to remove that information from our servers.
        </p>
      </div>
            </li>
            <li>
            <div id="changes">
        <h1>Changes to This Privacy Policy</h1>
        <p>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page. <br />{" "}
          <br />
          We will let you know via email and/or a prominent notice on our
          Service, prior to the change becoming effective and update “effective
          date” at the top of this Privacy Policy. <br /> <br />
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </p>
      </div>
            </li>
            <li>
            <div id="contact">
        <h1>Contact Us</h1>
        <p>If you have any questions about this Privacy Policy, please contact us by email: care@kwangu.health</p>
      </div>
            </li>
      </ol>
   

  
    </section>
  );
};
export default ContentSection;
